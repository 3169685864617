<template>
  <WaitWrapper :loading="loadingGetSettings || loadingSetSettings">
    <div>
      <h2 class="main-title mb-10">Settings</h2>
    </div>
    <section class="ml-5">
      <h4>Theme</h4>
      <v-divider class="mr-10"></v-divider>
      <v-select
        class="theme"
        :items="themeOptions"
        v-model="theme"
        item-value="value"
        solo
        dense
        @change="themeSelected"
      ></v-select>

      <h4>Email</h4>
      <v-divider class="mr-10"></v-divider>
      <div class="email-updates">
        <v-card
          max-width="275px"
          min-width="150px"
          outlined
          flat
          class="email-preferences"
        >
          <v-list>
            <v-list-item>
              <v-checkbox
                v-model="notificationChecked"
                @change="checkboxUpdated"
              ></v-checkbox>
              <v-list-item-title> After each notification </v-list-item-title>
            </v-list-item>
          </v-list>
          <div class="d-flex digest-options-container">
            <v-list>
              <v-list-item>
                <div class="d-flex align-center">
                  <div class="d-flex align-center mb-6">
                    <v-checkbox
                      v-model="digestChecked"
                      @change="checkboxUpdated"
                    ></v-checkbox>
                    <div class="select-label">Digest:</div>
                  </div>
                  <v-select
                    :items="digestOptions"
                    v-model="digestPreference"
                    item-value="text"
                    solo
                    dense
                    @change="digestSelected"
                  ></v-select>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
        <div class="d-flex justify-content-start buttons-wrapper pb-1 mt-3">
          <v-btn
            class="mr-1 white--text"
            :disabled="saveDisabled"
            color="green"
            @click="savePreference"
            >Save</v-btn
          >
          <v-btn class="mr-2" outlined @click="cancelEdit">Cancel</v-btn>
        </div>
      </div>
    </section>
  </WaitWrapper>
</template>

<script>
import { mapActions, mapState } from "vuex";
import WaitWrapper from "@/components/WaitWrapper";

export default {
  name: "UserSettings",
  components: {
    WaitWrapper,
  },
  data() {
    return {
      x: 40,
      y: 45,
      saveDisabled: true,
      digestPreference: "Weekly",
      digestOptions: [
        {
          text: "Daily",
          value: "daily",
        },
        {
          text: "Weekly",
          value: "weekly",
        },
        {
          text: "Monthly",
          value: "monthly",
        },
      ],
      themeOptions: [
        {
          text: "Light",
          value: "light",
        },
        {
          text: "Dark",
          value: "dark",
        },
        {
          text: "Auto",
          value: "auto",
        },
      ],
      theme: "auto",
      notificationChecked: false,
      digestChecked: false,
    };
  },
  computed: {
    ...mapState({
      userSettings: (state) => state.user.userSettings,
      loadingGetSettings: (state) => state.user.loading.getUserSettings,
      loadingSetSettings: (state) => state.user.loading.updateUserSettings,
    }),
  },
  async mounted() {
    await this.getUserSettings();

    this.setInitialPreferences();
  },
  methods: {
    ...mapActions({
      getUserSettings: "user/getUserSettings",
      updateUserSettings: "user/updateUserSettings",
    }),
    setInitialPreferences() {
      if (this.userSettings?.notifications) {
        if (this.userSettings.notifications.email_all) {
          this.notificationChecked = true;
        }
        if (
          this.userSettings.notifications.email_digest_freq &&
          this.userSettings.notifications.email_digest_freq !== "none"
        ) {
          this.digestChecked = true;
          let frequency = this.userSettings.notifications.email_digest_freq;
          // Capitalize first letter of email digest freq to match list item
          frequency = frequency[0].toUpperCase() + frequency.slice(1);
          this.digestPreference = frequency;
        }
        if (!this.userSettings.notifications.email_all) {
          this.notificationChecked = false;
        }
        if (!this.userSettings.notifications.email_digest_freq) {
          this.digestChecked = false;
          this.digestPreference = "Weekly";
        }
        if (this.userSettings?.theme) {
          this.theme = this.userSettings.theme;
        }
      }
    },
    checkboxUpdated() {
      const obj = {
        email_all: this.notificationChecked,
        email_digest_freq: this.digestChecked
          ? this.digestPreference.toLowerCase()
          : null,
      };
      if (
        JSON.stringify(this.userSettings?.notifications) !== JSON.stringify(obj)
      ) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },
    themeSelected() {
      if (this.theme !== this.userSettings?.theme) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },
    digestSelected(event) {
      if (event) {
        this.digestChecked = true;
      }
      const obj = {
        email_all: this.notificationChecked,
        email_digest_freq: this.digestChecked
          ? this.digestPreference.toLowerCase()
          : null,
      };
      if (
        JSON.stringify(this.userSettings?.notifications) !== JSON.stringify(obj)
      ) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },
    async savePreference() {
      let preferenceObj = {};
      this.saveDisabled = true;

      preferenceObj.email_all_notifications = this.notificationChecked;
      preferenceObj.theme = this.theme;

      if (this.digestChecked && this.digestPreference) {
        preferenceObj.email_digest_freq = this.digestPreference.toLowerCase();
      }
      if (!this.digestChecked) {
        preferenceObj.email_digest_freq = "none";
      }

      await this.updateUserSettings(preferenceObj);
      await this.getUserSettings();

      // If user theme is auto, set to OS theme. Or if user theme is not set default to auto.
      if (
        this.userSettings?.theme === "auto" ||
        this.userSettings == null ||
        Object.keys(this.userSettings).length === 0
      ) {
        this.$vuetify.theme.dark =
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;
      } else {
        this.$vuetify.theme.dark = this.userSettings?.theme === "dark";
      }

      if (!this.digestChecked) {
        this.digestPreference = "Weekly";
      }
    },
    cancelEdit() {
      this.saveDisabled = true;
      this.notificationChecked = this.userSettings?.notifications?.email_all;
      this.theme = this.userSettings?.theme ?? "auto";
      if (this.userSettings?.notifications.email_digest_freq) {
        let frequency = this.userSettings.notifications.email_digest_freq;
        // Capitalize first letter of email digest freq to match list item
        frequency = frequency[0].toUpperCase() + frequency.slice(1);
        this.digestChecked = true;
        this.digestPreference = frequency;
      } else {
        this.digestChecked = false;
        this.digestPreference = "Weekly";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-updates,
.theme {
  max-width: 275px;
}

.preference-title {
  font:
    16px Roboto,
    "sans-serif";
}

.email-preferences {
  .v-list {
    background-color: var(--v-secondary-base);
    padding: 0;
  }
}

.email-options__list {
  border: 1px solid rgba(153, 153, 153, 0.9);
}

.digest-options-container {
  background-color: var(--v-background-base);
}

.select-label {
  margin-right: 1rem;
  font-size: 1rem;
}

.colored-text {
  color: #1c64ca !important;
}

.buttons-wrapper {
  //margin-top: 20px;
  button {
    width: 100px;
  }
}
</style>
